import { Link } from 'react-router-dom';

import avatar from '../images/avatar.png';

import github from '../images/github.svg';
import twitter from '../images/twitter.svg';
import instagram from '../images/instagram.svg';
import facebook from '../images/facebook.svg';
import linkedin from '../images/linkedin.svg';
import xing from '../images/xing.svg';

const Home = () => {
    return (
        <>
            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="column" style={{ marginTop: '12%' }}>
                            <img src={avatar} className="avatar box-shadow" alt="Avatar" />

                            <h1>Nicola Stefanac</h1>

                            <p>Tech-Enthusiast, Unternehmer, Open-Source-Befürworter und Inhaber von <a href="https://www.unetifi.com/" target="_blank" rel="noopener noreferrer">Unetifi</a>.</p>

                            <a className="button button-github box-shadow" href="https://github.com/nicolastefanac" target="_blank" rel="noopener noreferrer">
                                <img src={github} className="icon" alt="GitHub Logo" />GitHub
                            </a>

                            <a className="button button-twitter box-shadow" href="https://twitter.com/nicolastefanac" target="_blank" rel="noopener noreferrer">
                                <img src={twitter} className="icon" alt="Twitter Logo" />Twitter
                            </a>

                            <a className="button button-instagram box-shadow" href="https://www.instagram.com/nicolastefanac/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} className="icon" alt="Instagram Logo" />Instagram
                            </a>

                            <a className="button button-facebook box-shadow" href="https://www.facebook.com/nicolastefanac/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} className="icon" alt="Facebook Logo" />Facebook
                            </a>

                            <a className="button button-linkedin box-shadow" href="https://www.linkedin.com/in/nicola-stefanac-952ab022b" target="_blank" rel="noopener noreferrer">
                                <img src={linkedin} className="icon" alt="LinkedIn Logo" />LinkedIn
                            </a>

                            <a className="button button-xing box-shadow" href="https://www.xing.com/profile/Nicola_Stefanac/" target="_blank" rel="noopener noreferrer">
                                <img src={xing} className="icon" alt="Xing Logo" />Xing
                            </a>

                            <br />

                            <br />

                            <Link to="/impressum">Impressum</Link>

                            <p className="footer">&copy; {new Date().getFullYear()} <strong>Nicola Stefanac</strong> – Alle Rechte vorbehalten.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Home;