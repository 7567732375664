import { Outlet } from 'react-router-dom';

import '../css/fonts.css';
import '../css/normalize.css';
import '../css/skeleton-auto.css';
import '../css/brands.css';

const Layout = () => {
    return (
        <>
            <Outlet />
        </>
    )
};

export default Layout;